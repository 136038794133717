import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Paper } from '../../atoms/Paper/Paper'
import { Text } from '../../atoms/Typography/Text'
import { Button } from '../../molecules/Button/Button'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

const HeadingSpace = styled.div`
  margin-bottom: ${Spacings['22']};
`

const BlackText = styled(Text)`
  color: ${Colors.black};
`

const AnchorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Breakpoints.small} {
    justify-content: center;
  }
`

const StyledAnchor = styled(Link)`
  margin-top: ${Spacings['32']};
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  ${Breakpoints.small} {
    & > span {
      padding: 0;
    }
  }
`
export const PaperSection = ({
  headerText,
  contentText,
  buttonText,
  buttonLink,
  backgroundColor,
}) => {
  return (
    <SectionWrapper background={backgroundColor} paddingBottom>
      <Paper>
        <FlexWrapper>
          <div>
            <HeadingSpace>
              <BlackText font='Roboto' variant='xLarge'>
                <Bold>{headerText}</Bold>
              </BlackText>
            </HeadingSpace>
            <Text variant='large'>{contentText}</Text>
          </div>
          <AnchorWrapper>
            <StyledAnchor to={buttonLink}>
              <StyledButton>
                <Text font='Roboto' variant='medium'>
                  <Bold>{buttonText}</Bold>
                </Text>
              </StyledButton>
            </StyledAnchor>
          </AnchorWrapper>
        </FlexWrapper>
      </Paper>
    </SectionWrapper>
  )
}

PaperSection.propTypes = {
  headerText: PropTypes.string,
  contentText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  backgroundColor: PropTypes.string,
}
