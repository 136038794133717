import PropTypes from 'prop-types'
import React from 'react'

import { Text } from '../../atoms/Typography/Text'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Colors } from '../../styles/colors'

export const OnlyText = ({ contentText, backgroundColor }) => {
  return (
    <SectionWrapper
      backgroundColor={backgroundColor ?? Colors.lighterGrey}
      paddingTop
      paddingBottom
    >
      <Text variant='large'>{contentText}</Text>
    </SectionWrapper>
  )
}

OnlyText.propTypes = {
  contentText: PropTypes.node,
  backgroundColor: PropTypes.string,
}
