import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { Colors } from '../../styles/colors'
import { SectionWrapper } from '../SectionWrapper/SectionWrapper'

const CenteredHeader = styled(Header)`
  color: ${props => props.styles.headerColor ?? Colors.white};
  text-align: center;
  margin: 0;
`

export const BigHeader = ({ headerText, backgroundColor, headerColor }) => (
  <SectionWrapper
    backgroundColor={backgroundColor ?? Colors.primary}
    paddingTop
    paddingBottom
  >
    <CenteredHeader variant='heading2' styles={{ headerColor }}>
      {headerText}
    </CenteredHeader>
  </SectionWrapper>
)

BigHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  headerColor: PropTypes.string,
}
