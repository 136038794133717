import React from 'react'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { BigHeader } from '../molecules/BigHeader/BigHeader'
import { OnlyText } from '../organisms/HeaderAndText/OnlyText'
import { PaperSection } from '../organisms/PaperSection/PaperSection'
import { Colors } from '../styles/colors'

const ZabiegiRefundowanePage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <BigHeader headerText='Zabiegi refundowane' />
    <OnlyText
      contentText={
        <>
          Od 2001 roku współpracujemy z Narodowym Funduszem Zdrowia oferując naszym pacjentom refundowane zabiegi.
          Skierowanie do poradni rehabilitacyjnej oraz na zabiegi fizjoterapeutyczne może wystawić każdy lekarz
          ubezpieczenia zdrowotnego.
          <br />
          <br />
          Aby skorzystać z refundowanej porady specjalistycznej lub zabiegów fizjoterapeutycznych wymagane jest
          skierowanie do poradni rehabilitacyjnej lub skierowanie na zabiegi fizjoterapeutyczne od lekarza ubezpieczenia
          zdrowotnego, tj. takiego, który pracuje w ramach umowy z NFZ.
        </>
      }
    />
    <PaperSection
      headerText='Skierowanie do poradni rehabilitacyjnej'
      contentText='Jeżeli otrzymałeś takie skierowanie od swojego lekarza rodzinnego lub innego specjalisty, w pierwszej kolejności musisz udać się na konsultacje do lekarza specjalisty rehabilitacji. Podczas takiej wizyty, lekarz przepisuje konkretne zabiegi fizjoterapeutyczne na Twoje dolegliwości.'
      backgroundColor={`linear-gradient(0deg, ${Colors.white} 65%, ${Colors.lighterGrey} 65%);`}
      buttonText='Umów wizytę do lekarza'
      buttonLink='/kontakt'
    />
    <PaperSection
      headerText='Skierowanie na zabiegi fizjoterapeutyczne'
      contentText={
        <>
          Jeżeli trzymasz takie skierowanie w rękach, zapewne zauważysz na nim wypisane poszczególne zabiegi. Z takim
          skierowaniem najlepiej udać się osobiście do rejestracji, wówczas łatwiej jest ustalić termin Twojej
          rehabilitacji.
          <br />
          Jeżeli na skierowaniu na zabiegi fizjoterapeutyczne nie masz rozpisanych zabiegów, oznacza to, że zostałeś
          skierowany na wizytę fizjoterapeutyczną do fizjoterapeuty, który po wywiadzie i diagnostyce funkcjonalnej
          ustali konkretne zabiegi na Twoje dolegliwości.
        </>
      }
      backgroundColor={Colors.white}
      buttonText='Umów się na zabieg lub wizytę fizjoterapeutyczną'
      buttonLink='/kontakt'
    />
  </Main>
)

export default ZabiegiRefundowanePage
